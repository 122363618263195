import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//components
import Sidebar from "../layout/Sidebar";
import NumberOfOrg from "./dashboardCards/numberOfOrg";
import JobPlacements from "./dashboardCards/jobPlacements";
import NumberOfOpportunities from "./dashboardCards/numberOfOpportunities";
import NumberOfVolunteers from "./dashboardCards/numberOfVolunteers";
import FirstTimeVolunteers from "./dashboardCards/firstTimeVolunteers";
import OrganizationsTypes from "./dashboardCards/organizationsTypes";
import CountryOfRegistration from "./dashboardCards/countryOfRegistration";
import VolunteersNationalities from "./dashboardCards/volunteersNationalities";
import VolunteersDistrict from "./dashboardCards/volunteersDistrict";
import VolunteersExpertise from "./dashboardCards/volunteersExpertise";
import CostsSaved from "./dashboardCards/costsSaved";
import VolunteersSkill from "./dashboardCards/volunteersSkill";
import OrganizationsMasterclass from "./dashboardCards/organizationsMasterclass";
import VolunteersGender from "./dashboardCards/volunteersGender";
import VolunteersOccupation from "./dashboardCards/volunteersOccupation";
import VolunteersAny from "./dashboardCards/volunteersAny";
import VolunteersPoint from "./dashboardCards/volunteersPoint";
import VolunteersHours from "./dashboardCards/volunteersHours";
import VolunteersRegion from "./dashboardCards/volunteersRegion";

//redux
import { getStatistics } from "../../redux/auth/authActions";

//utils
import isAuth from "../../utils/isAuth";

import "./dashboard.css";
import OrganizationsSubscribe from "./dashboardCards/organizationsSubscribe";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { statistics } = useSelector((state) => state.authReducer);
  const [statisticsData, setStatisticsData] = useState([]);
  const [googleChartData, setGoogleChartData] = useState([]);
  const [countryOfRegistrationData, setCountryOfRegistrationData] = useState(
    []
  );
  const [volunteersNationalitiesData, setVolunteersNationalitiesData] =
    useState([]);
  const [volunteersDistrictData, setVolunteersDistrictData] = useState([]);
  const [volunteersExpertiseData, setVolunteersExpertiseData] = useState([]);
  const [volunteersSkillData, setVolunteersSkillData] = useState([]);
  const [volunteersGenderData, setVolunteersGenderData] = useState([]);
  const [volunteersOccupation, setVolunteersOccupation] = useState([]);
  const [volunteersRegion, setVolunteersRegion] = useState([]);

  useEffect(() => {
    dispatch(getStatistics());
  }, [dispatch]);

  useEffect(() => {
    if (statistics) {
      setStatisticsData(statistics);
      if (statistics?.organization_type != undefined) {
        const googleChartTableData = [
          ["Type", "Count"],
          ...statistics?.organization_type?.map((item) => [
            item.type,
            item.count,
          ]),
        ];
        setGoogleChartData(googleChartTableData);
      }

      if (statistics?.total_volunteers_gender != undefined) {
        const volunteersGender = [
          ["gender", "count"],
          ...statistics?.total_volunteers_gender?.map((item) => [
            item.gender,
            item.count,
          ]),
        ];
        setVolunteersGenderData(volunteersGender);
      }

      if (statistics?.totalVolunteersRegion != undefined) {
        const volunteersRegion = [
          ["region", "count"],
          ...statistics?.totalVolunteersRegion?.map((item) => [            
            item?.regions,
            item.count,
          ]),
        ];
        setVolunteersRegion(volunteersRegion);
      }

      if (statistics?.total_volunteers_occupation != undefined) {
        const volunteersOccupation = [
          ["occupation", "count"],
          ...statistics?.total_volunteers_occupation?.map((item) => [
            item.occupation,
            item.count,
          ]),
        ];
        setVolunteersOccupation(volunteersOccupation);
      }

      if (statistics?.total_orgs_country_of_registration != undefined) {
        const countryOfRegistration = [
          ["country_of_registration", "count"],
          ...statistics?.total_orgs_country_of_registration?.map((item) => [
            item.country?.name,
            item.count,
          ]),
        ];
        setCountryOfRegistrationData(countryOfRegistration);
      }

      if (statistics?.total_volunteers_nationalities != undefined) {
        const volunteersNationalities = [
          ["country_of_registration", "count"],
          ...statistics?.total_volunteers_nationalities?.map((item) => [
            item.nationality?.name,
            item.count,
          ]),
        ];
        setVolunteersNationalitiesData(volunteersNationalities);
      }

      if (statistics?.total_volunteers_district != undefined) {
        const volunteersDistrict = [
          ["district_id", "count"],
          ...statistics?.total_volunteers_district?.map((item) => [
            item.district?.name,
            item.count,
          ]),
        ];
        setVolunteersDistrictData(volunteersDistrict);
      }

      if (statistics?.total_volunteers_expertise != undefined) {
        const volunteersExpertise = [
          ["expertise_id", "count"],
          ...statistics?.total_volunteers_expertise?.map((item) => [
            item.skill?.name,
            item.count,
          ]),
        ];
        setVolunteersExpertiseData(volunteersExpertise);
      }

      if (statistics?.total_volunteers_skill != undefined) {
        const VolunteersSkill = [
          ...statistics?.total_volunteers_skill?.map((item) => ({
            count: item.count,
            skill: item?.skill?.name,
          })),
        ];
        setVolunteersSkillData(VolunteersSkill);
      }
    }
  }, [statistics]);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  return (
    <div className="all">
      <div className="d-flex" id="wrapper">
        <Sidebar />

        <div id="page-content-wrapper">
          <div className="container-fluid home">
            <br></br>
            <br></br>

            <div className="row px-2">
              <div class="charts-div total-width">
                <NumberOfOrg
                  total_organizations={statisticsData?.total_organizations}
                />
                <NumberOfVolunteers
                  numberOfVolunteers={statisticsData?.total_volunteers}
                />
                <NumberOfOpportunities
                  total_opportunities={statisticsData?.total_opportunities}
                />
              </div>

              {/* Organizations */}

              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "black",
                  textAlign: "center",
                  width: "100%",
                  marginTop: "30px",
                }}
              >
                Organizations
              </div>
              <div class="charts-div total-width">
                <CostsSaved coastSaved={statisticsData?.total_cost} />
                <OrganizationsMasterclass
                  organizationsMasterclass={statisticsData?.total_masterclass}
                />
                <OrganizationsSubscribe
                  total_subscribe={statisticsData?.total_subscribe}
                />
              </div>
              <div class="charts-div">
                {googleChartData && (
                  <OrganizationsTypes organizationsTypes={googleChartData} />
                )}
                {countryOfRegistrationData && (
                  <CountryOfRegistration
                    countryOfRegistration={countryOfRegistrationData}
                  />
                )}
                <div className="col-lg-4 col-sm-4"></div>
              </div>

              {/* Volunteers */}

              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "black",
                  textAlign: "center",
                  width: "100%",
                  marginTop: "30px",
                }}
              >
                Volunteers
              </div>

              <div class="charts-div total-width">
                <FirstTimeVolunteers
                  firstTimeVolunteers={
                    statisticsData?.total_first_time_volunteer
                  }
                />
                <JobPlacements jobPlacements={statisticsData?.total_job} />
                <VolunteersAny
                  total_volunteers_any={statisticsData?.total_volunteers_any}
                />
              </div>

              <div class="charts-div total-width">
                <VolunteersHours
                  total_volunteers_hours={
                    statisticsData?.total_volunteers_hours
                  }
                />
                <VolunteersPoint
                  total_volunteers_point={
                    statisticsData?.total_volunteers_point
                  }
                />
                <div className="col-lg-4 col-sm-4"></div>
              </div>

              <div class="charts-div">
                {volunteersExpertiseData && (
                  <VolunteersExpertise
                    volunteersExpertise={volunteersExpertiseData}
                  />
                )}
                {volunteersNationalitiesData && (
                  <VolunteersNationalities
                    volunteersNationalities={volunteersNationalitiesData}
                  />
                )}
                {volunteersDistrictData && (
                  <VolunteersDistrict
                    volunteersDistrict={volunteersDistrictData}
                  />
                )}
              </div>

              <div class="charts-div">
                {volunteersGenderData && (
                  <VolunteersGender
                    volunteersGenderData={volunteersGenderData}
                  />
                )}
                {volunteersGenderData && (
                  <VolunteersOccupation
                    volunteersOccupation={volunteersOccupation}
                  />
                )}
                {/* regions */}
                {volunteersRegion && (
                  <VolunteersRegion totalVolunteersRegion={volunteersRegion} />
                )}
              </div>

              <div style={{ width: "95%" }}>
                {volunteersSkillData && (
                  <VolunteersSkill volunteersSkill={volunteersSkillData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
