import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

function jobPlacements(props) {
  return (
    <>
      <DashboardCardStat
        title={props.jobPlacements}
        description="Total number of Volunteers Open to work "
        icon={
          <div className="circle-container">
            <FontAwesomeIcon icon={faBriefcase} size="xs" />
          </div>
        }
      />
    </>
  );
}

export default jobPlacements;
