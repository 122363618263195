import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ReactDatatable from "@ashvin27/react-datatable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import VolunteerViewModal from "./VolunteerViewModal";
import VolunteerEditModal from "./VolunteerEditModal";

//redux
import { getVolunteers } from "../../redux/volunteer/volunteerActions.js";

//utils
import isAuth from "../../utils/isAuth";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
import { create } from "@mui/material/styles/createTransitions.js";

function Volunteer() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter((entry, index) => index !== 0);
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Volunteers.xlsx");
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, volunteers, error } = useSelector(
    (state) => state.volunteerReducer
  );
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getVolunteers());
  }, [dispatch]);
  const [timeBased, setTimeBaseds] = useState("");
  const [skillBased, setSkillBaseds] = useState("");

  const calculeAgeFromDOB = (dob) => {
    if (!dob) return;

    const dobDate = new Date(dob); // Convert the input to a Date object
    if (isNaN(dobDate)) return "Invalid Date"; // Handle invalid date input

    const today = new Date();
    let age = today.getFullYear() - dobDate.getFullYear();

    // Adjust the age if the birth date hasn't occurred yet this year
    const hasBirthdayPassed =
      today.getMonth() > dobDate.getMonth() ||
      (today.getMonth() === dobDate.getMonth() &&
        today.getDate() >= dobDate.getDate());

    if (!hasBirthdayPassed) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    if (volunteers && volunteers.data) {
      const mappedRecords = volunteers.data.map((volunteer) => {
        return {
          key: volunteer.id,
          id: volunteer.id,
          first_Name: volunteer.first_name,
          last_Name: volunteer.last_name,
          email: volunteer.email,
          gender: volunteer.gender,
          phone_Number:
            volunteer.phone_number !== null && volunteer.phone_number !== ""
              ? volunteer.phone_number
              : "None",
          DOB: volunteer.date_of_birth,
          reason: volunteer.reason,
          experience: volunteer.experience,
          linkedIn: volunteer.linked_in,
          occupation: volunteer.occupation,
          frequency: volunteer.frequency,
          availability: volunteer.availability,
          any_Date: volunteer.any_date,
          date_from: new Date(volunteer.date_from).toISOString().split("T")[0],
          date_to: new Date(volunteer.date_to).toISOString().split("T")[0],
          volunteered_Before: volunteer.volunteered_before,
          comments: volunteer.comments,
          points: volunteer.points,
          timeBased: volunteer.timeBased,
          skillBased: volunteer.skillBased,
          open_To_Work: volunteer.open_to_work,
          institution: volunteer.institution,
          objective: volunteer.objective,
          country_id: volunteer.country_id,
          status: volunteer.status,
          lastSeen: volunteer.lastSeen,
          createdAt: volunteer.createdAt,
          age: calculeAgeFromDOB(volunteer.date_of_birth),
        };
      });
      setRecords(mappedRecords);
    }
  }, [volunteers]);

  const initialColumns = [
    { field: "first_Name", headerName: "First Name", flex: 1 },
    { field: "last_Name", headerName: "Last Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "gender", headerName: "Gender", flex: 1 },
    { field: "phone_Number", headerName: "Phone", flex: 1 },
    { field: "DOB", headerName: "DOB", flex: 1 },
    { field: "age", headerName: "Age", flex: 1 },
    { field: "points", headerName: "Points", flex: 1 },
    { field: "timeBased", headerName: "Time Based", flex: 1 },
    { field: "skillBased", headerName: "Skill Based", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "lastSeen", headerName: "Last Seen", flex: 1 },
    { field: "volunteered_Before", headerName: "Volunteered Before", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    { field: "reason", headerName: "Reason", flex: 1 },
    { field: "experience", headerName: "Experience", flex: 1 },
    { field: "linkedIn", headerName: "LinkedIn", flex: 1 },
    { field: "occupation", headerName: "Occupation", flex: 1 },
    { field: "frequency", headerName: "Frequency", flex: 1 },
    { field: "availability", headerName: "Availability", flex: 1 },
    { field: "any_Date", headerName: "Any Date", flex: 1 },
    { field: "date_from", headerName: "Date From", flex: 1 },
    { field: "date_to", headerName: "Date To", flex: 1 },
    { field: "comments", headerName: "Comments", flex: 1 },
    { field: "open_To_Work", headerName: "Open To Work", flex: 1 },
    { field: "institution", headerName: "Institution", flex: 1 },
    { field: "objective", headerName: "Objective", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-volunteer-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setTimeBaseds(record.row.timeBased);
                setSkillBaseds(record.row.skillBased);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const hiddenColumnsList = [
    "createdAt",
    "objective",
    "institution",
    "open_To_Work",
    "comments",
    "volunteered_Before",
    "date_to",
    "date_from",
    "any_Date",
    "availability",
    "frequency",
    "occupation",
    "linkedIn",
    "experience",
    "reason",
  ];

  const [columns, setColumns] = useState(initialColumns);
  const [hiddenColumns, setHiddenColumns] = useState(hiddenColumnsList);

  // Handle changes in column visibility
  const handleColumnVisibilityChange = (field, isVisible) => {
    if (isVisible) {
      setHiddenColumns(hiddenColumns.filter((col) => col !== field));
    } else {
      setHiddenColumns([...hiddenColumns, field]);
    }
  };

  // Initialize the columns with their visibility status
  const visibleColumns = columns.map((column) => ({
    ...column,
    hide: hiddenColumns.includes(column.field),
  }));

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <VolunteerViewModal id={id} />
        <VolunteerEditModal
          id={id}
          timeBased={timeBased}
          skillBased={skillBased}
          setTimeBaseds={setTimeBaseds}
          setSkillBaseds={setSkillBaseds}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header title="Volunteer" subtitle="List of Volunteers" />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={visibleColumns}
                      components={{ Toolbar: GridToolbar }}
                      onColumnVisibilityChange={handleColumnVisibilityChange}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Volunteer;
