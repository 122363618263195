import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { getOrganizationById } from "../../redux/organization/organizationActions";

//component
import Loading from "../layout/Loading";

//css
import "./Organization.css";

function OrganizationViewModal({ id }) {
  const dispatch = useDispatch();
  const { oneLoading, oneorganization } = useSelector(
    (state) => state.organizationReducer
  );
  const [myOrganization, setMyOrganization] = useState();

  useEffect(() => {
    if (oneorganization?.Organization !== undefined) {
      let org = {
        name: oneorganization.Organization.name,
        type: oneorganization.Organization.type,
        registration_number: oneorganization.Organization.registration_number,
        country_of_registration: oneorganization.Organization.country?.name,
        district: oneorganization.Organization.district?.name,
        about: oneorganization.Organization.about,
        email_address: oneorganization.Organization.email_address,
        approved: oneorganization.Organization.approved ? "Yes" : "No",
        instagram: oneorganization.Organization.instagram,
        linked_in: oneorganization.Organization.linked_in,
        facebook: oneorganization.Organization.facebook,
        website: oneorganization.Organization.website,
        phone: oneorganization.Organization.phone_number,
        masterclass: oneorganization.Organization.masterclass ? "Yes" : "No",
        occupation: oneorganization.Organization.occupation,
        contactPersonName: oneorganization.Organization.contactPersonName,
      };
      setMyOrganization(org);
    }
  }, [oneorganization]);

  useEffect(() => {
    if (id !== 0) dispatch(getOrganizationById(id));
  }, [dispatch, id]);

  return (
    <div className="modal fade" id="view-organization-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Organization View More </h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          {oneLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form>
                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Name
                      </span>
                      <input
                        type="text"
                        value={
                          myOrganization?.name !== null
                            ? myOrganization?.name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Type
                      </span>
                      <input
                        type="text"
                        value={
                          myOrganization?.type !== null
                            ? myOrganization?.type || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Country
                      </span>
                      <input
                        type="text"
                        value={
                          myOrganization?.country_of_registration !== null
                            ? myOrganization?.country_of_registration || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        District
                      </span>
                      <input
                        type="text"
                        value={
                          myOrganization?.district !== null
                            ? myOrganization?.district || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      About
                    </span>
                    <textarea
                      value={
                        myOrganization?.about !== null
                          ? myOrganization?.about || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myOrganization?.about !== null ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Email
                    </span>
                    <input
                      type="text"
                      value={
                        myOrganization?.email_address !== null
                          ? myOrganization?.email_address || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Phone
                      </span>
                      <input
                        type="text"
                        value={
                          myOrganization?.phone !== null &&
                          myOrganization?.phone !== ""
                            ? myOrganization?.phone || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Registration NB
                      </span>
                      <input
                        type="text"
                        value={
                          myOrganization?.registration_number !== null
                            ? myOrganization?.registration_number || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Approved
                      </span>
                      <input
                        type="text"
                        value={
                          myOrganization?.approved !== null
                            ? myOrganization?.approved || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Master Class
                      </span>
                      <input
                        type="text"
                        value={
                          myOrganization?.masterclass !== null
                            ? myOrganization?.masterclass || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Occupation
                    </span>
                    <input
                      type="text"
                      value={
                        myOrganization?.occupation !== null
                          ? myOrganization?.occupation || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Instagram
                    </span>
                    <input
                      type="text"
                      value={
                        myOrganization?.instagram !== null
                          ? myOrganization?.instagram || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      LinkedIn
                    </span>
                    <input
                      type="text"
                      value={
                        myOrganization?.linked_in !== null
                          ? myOrganization?.linked_in || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Facebook
                    </span>
                    <input
                      type="text"
                      value={
                        myOrganization?.facebook !== null
                          ? myOrganization?.facebook || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Website
                    </span>
                    <input
                      type="text"
                      value={
                        myOrganization?.website !== null
                          ? myOrganization?.website || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />
                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Contact Person Name
                    </span>
                    <input
                      type="text"
                      value={
                        myOrganization?.contactPersonName !== null
                          ? myOrganization?.contactPersonName || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrganizationViewModal;
