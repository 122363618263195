import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

function costSaved(props) {
  return (
    <>
      <DashboardCardStat
        title={props.coastSaved}
        description="Cost Saved"
        icon={
          <div className="circle-container">
            <FontAwesomeIcon icon={faDollarSign} />
          </div>
        }
      />
    </>
  );
}

export default costSaved;
