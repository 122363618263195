import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { GrCurrency } from "react-icons/gr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSellcast } from "@fortawesome/free-brands-svg-icons";

function OrganizationsSubscribe(props) {
  return (
    <>
      <DashboardCardStat
        title={props.total_subscribe}
        description="Organizations Subscribed"
        icon={
          <div className="circle-container">
            <FontAwesomeIcon icon={faSellcast} />{" "}
          </div>
        }
      />
    </>
  );
}

export default OrganizationsSubscribe;
