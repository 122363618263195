import React from "react";
import { Chart } from "react-google-charts";

function organizationsTypes(props) {  
  const options = {
    legend: { position: "right" }, // Display legend to the right
    pieSliceText: "label", // Show percentage labels on slices
    chartArea: { width: "100%", height: "80%" }, // Adjust chart area
    tooltip: { showColorCode: true }, // Enhance tooltip display
    slices: [
      { color: "#4caf50" }, // Customize slice colors if necessary
      { color: "#ff9800" },
      { color: "#2196f3" },
      { color: "#9c27b0" },
      { color: "#f44336" },
    ],
  };

  return (
    <div
      className="col-lg-4 col-sm-4 shadow-lg"
      style={{
        backgroundColor: "#ffffff",
        padding: "30px",
        borderRadius: "20px",
      }}
    >
      <div className="chart-title">Organizations Type</div>
      <div class="horizontal-line"></div>
      <Chart
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={props?.organizationsTypes}
        options={options}
        width={"100%"}
        height={"auto"}
      />
    </div>
  );
}

export default organizationsTypes;
